import ApplicationController from '../application_controller'

export default class extends ApplicationController {

    initialize() {

    }

    connect() {

        $("#product_attribute_type_id").on("change", function () {
            let product_attribute_type_id = $("#product_attribute_type_id :selected").val();

            $.ajax({
                url: '/admin/product_attributes/product_attribute_type_value',
                method: "GET",
                dataType: 'script',
                data: {
                    'format': 'json',
                    'product_attribute_type_id': product_attribute_type_id
                },
                success: function (data) {
                    if (data === '1') {
                        $('#value_field_group').show() &&  $('#file_field_group').hide();
                    }else if(data === '0'){
                        $('#value_field_group').hide() && $('#file_field_group').hide();
                    }else if(data === '2'){
                        $('#file_field_group').show() &&  $('#value_field_group').hide();
                    }
                }
            });
        });
    }
}
