import ApplicationController from "../application_controller";

export default class extends ApplicationController {

    initialize() {
    }

    connect() {
        if ($('#containment_sortable_items').length > 0) {
            let table_width = $('#containment_sortable_items').width();
            let cells = $('.table').find('tr')[0].cells.length;
            let desired_width = table_width / cells + 'px';
            $('.table td').css('width', desired_width);
        }
        $('#containment_sortable_items').sortable({
            axis: 'y',
            cursor: 'move',
            containment: '#containment_sortable_items',
            sort: function(e, ui) {
            },
            stop: function(e, ui) {
                ui.item.removeClass('active-item-shadow');
            },
            update: function(event, ui) {
                var all_row_ids = $(this).sortable('toArray', { attribute: "data-item-id" });
                let packaging_item_path = document.getElementById('containment_sortable_items').dataset.packagingItem;
                $.ajax({
                    url: packaging_item_path,
                    method: 'POST',
                    data: {
                        ids: all_row_ids
                    },
                    success: function(){
                    }
                });


            }
        });
    }

}