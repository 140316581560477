import {Controller} from "stimulus"

export default class extends Controller {

    // HTML Element Example
    // <input type="text" data-controller="modules--daterangepicker"
    // data-modules--daterangepicker-config="{showDropdowns:true,timePicker:true,timePicker24Hour:true,locale:{format:DD/MM/YYYY HH:mm}}">

    static values = {
        startDate: String,
        endDate: String
    }

    initialize() {
        let config = this.data.get('config')
        this.config = config ? JSON.parse(config) : {}
        this.daterangepicker = $(this.element).daterangepicker(this.config)
    }

    connect() {
        if (this.hasStartDateValue) {
            this.daterangepicker.data('daterangepicker').setStartDate(this.startDateValue)
        }
        if (this.hasEndDateValue) {
            this.daterangepicker.data('daterangepicker').setEndDate(this.endDateValue)
        }
    }

    // Original JS
    // $('[data-behavior=daterangepicker]').daterangepicker({
    //     showDropdowns: true,
    //     timePicker: true,
    //     timePicker24Hour: true,
    //     locale: {
    //         format: 'DD/MM/YYYY HH:mm'
    //     }
    // });

}