import ApplicationController from "../../controllers/admin/application_controller"

export default class extends ApplicationController{

    initialize() {
        let config = this.data.get('config')
        this.config = config ? JSON.parse(config) : {}
        if (!this.config['theme']) this.config['theme'] = 'bootstrap4'
        if (this.config['allowClear'] === 'undefined') this.config['allowClear'] = true
        this.select2 = $(this.element).select2(this.config)
    }

    connect() {
        $(this.element).on('select2:open', () => this._open())
        $(this.element).on('select2:select', () => this._change())
        $(this.element).on('select2:clear', () => this._change())
    }

    //private
    _open() {
        document.querySelector('.select2-search__field').focus();
    }

    _change() {
        let event = new Event('change', {bubbles: true})
        this.element.dispatchEvent(event);
    }

    // Original JS
    // $('.select2').select2({
    //     theme: 'bootstrap4'
    // });
    //
    // $('.select2').on('select2:open', () => {
    //     document.querySelector('.select2-search__field').focus();
    // });
}