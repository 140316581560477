import ApplicationController from '../application_controller'

export default class extends ApplicationController {

    static targets = ['selectProductionCapability', 'selectIkeaProduct', 'selectCheckType', 'selectPackagingItem', 'productAttributesDefault']
    static values = {
        productId: String,
        productionCapabilityIdSelected: String,
        ikeaProductIdSelected: String,
        checkTypeIdSelected: String,
        packagingItemIdSelected: String
    }

    initialize() {
        $([this.selectProductionCapabilityTarget, this.selectIkeaProductTarget, this.selectCheckTypeTarget, this.selectPackagingItemTarget])
            .on('select2:select select2:unselecting', function () {
                let event = new Event('change', {bubbles: true})
                this.dispatchEvent(event);
            })
    }

    connect() {
        $(this.selectProductionCapabilityTarget).val(this.productionCapabilityIdSelectedValue).trigger('change');
        $(this.selectIkeaProductTarget).val(this.ikeaProductIdSelectedValue).trigger('change');
        $(this.selectCheckTypeTarget).val(this.checkTypeIdSelectedValue).trigger('change');
        this.onChangeSelectCheckType()

        this._enableTarget(this.selectProductionCapabilityTarget, false)
        this._enableTarget(this.selectIkeaProductTarget, false)
        this._enableTarget(this.selectCheckTypeTarget, false)
        this._enableTarget(this.selectPackagingItemTarget, false)
    }

    onChangeSelectCheckType() {
        let checkTypeValue = $(this.selectCheckTypeTarget).val()
        if (checkTypeValue) {
            this._getPackagingItems(checkTypeValue)
        }
    }

    onChangeSelectPackagingItem() {
        let packagingItemValue = $(this.selectPackagingItemTarget).val()
        let ikeaProductValue = $(this.selectIkeaProductTarget).val()
        if (packagingItemValue && ikeaProductValue) {
            this._getDefaultAttributes(this.productIdValue, packagingItemValue, ikeaProductValue)
            this._showTarget(this.productAttributesDefaultTarget, true)
        } else {
            this._hideTarget(this.productAttributesDefaultTarget, true)
        }
    }

    //private

    _enableTarget(target, enable) {
        $(target).attr('disabled', !enable);
    }

    _showTarget(target) {
        $(target).show()
    }

    _hideTarget(target) {
        $(target).hide()
    }

    _getPackagingItems(incidenceDynamicCheckTypeId) {
        let context = this
        let url = '/admin/packaging_items/get_packaging_items'
        $.ajax({
            url: url,
            type: "GET",
            dataType: "script",
            data: {
                'format': 'json',
                'incidence_dynamic_check_type_id': incidenceDynamicCheckTypeId,
            },
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (data) {
                let packagingItems = JSON.parse(data)
                let packagingItemId = context.selectPackagingItemTarget.value
                $(context.selectPackagingItemTarget).empty().trigger('change');
                $.each(packagingItems, function (i, obj) {
                    let option = new Option(obj.name, obj.id);
                    $(context.selectPackagingItemTarget).append(option)
                });
                $(context.selectPackagingItemTarget).val(context.packagingItemIdSelectedValue).trigger('change');
                context.onChangeSelectPackagingItem()

            },
            error: function (error) {
                $.app.error(error.responseText)
            }
        })
    }

    _getDefaultAttributes(productId, packagingItemId, ikeaProductId) {
        let context = this
        let url = '/admin/product_attributes/edit_default_attributes'
        $.ajax({
            url: url,
            type: "GET",
            data: {
                'product_id': productId,
                'packaging_item_id': packagingItemId,
                'ikea_product_id': ikeaProductId
            },
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (data) {
            },
            error: function (error) {
                $.app.error(error.responseText)
            }
        })
    }
}