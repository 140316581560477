import ApplicationController from '../application_controller'

export default class extends ApplicationController {

    static targets = ['selectProductionCapability', 'selectIkeaProduct', 'selectCheckType', 'selectPackagingItem', 'saveAndMore', 'saveOnly']

    initialize() {
        $([this.selectProductionCapabilityTarget, this.selectIkeaProductTarget, this.selectCheckTypeTarget, this.selectPackagingItemTarget])
            .on('select2:select select2:unselecting', function () {
                let event = new Event('change', {bubbles: true})
                this.dispatchEvent(event);
            })
    }

    connect() {

        if (this.selectIkeaProductTarget.value) {
            this._enableTarget(this.selectCheckTypeTarget, true)
        } else {
            this._enableTarget(this.selectCheckTypeTarget, false)
        }
        if (this.selectCheckTypeTarget.value) {
            this._enableTarget(this.selectPackagingItemTarget, true)
        } else {
            this._enableTarget(this.selectPackagingItemTarget, false)
        }
        if (this.selectCheckTypeTarget.value) this._getPackagingItems(this.selectCheckTypeTarget.value)

    }

    onClickSaveAndMore() {
        let inputHidden = document.getElementById("add_more");
        inputHidden.value = true;
    }

    onClickSaveOnly(){
        let inputHidden = document.getElementById("add_more");
        inputHidden.value = false;
    }

    onChangeSelectIkeaProduct() {
        let ikeaProductValue = $(this.selectIkeaProductTarget).val()
        if (ikeaProductValue) {
            this._enableTarget(this.selectCheckTypeTarget, true)
        } else {
            this._enableTarget(this.selectCheckTypeTarget, false)
        }
        $(this.selectCheckTypeTarget).val(null).trigger('change');
        $(this.selectPackagingItemTarget).val(null).trigger('change');
        this._enableTarget(this.selectPackagingItemTarget, false)

    }

    onChangeSelectCheckType() {
        let checkTypeValue = $(this.selectCheckTypeTarget).val()
        if (checkTypeValue) {
            this._getPackagingItems(checkTypeValue)
            this._enableTarget(this.selectPackagingItemTarget, true)
        } else {
            this._enableTarget(this.selectPackagingItemTarget, false)
        }

        $(this.selectPackagingItemTarget).val(null).trigger('change');

    }

    onChangeSelectPackagingItem() {
        let packagingItemValue = $(this.selectPackagingItemTarget).val()
        let ikeaProductValue = $(this.selectIkeaProductTarget).val()
    }

    //private

    _enableTarget(target, enable) {
        $(target).attr('disabled', !enable);
    }

    _showTarget(target) {
        $(target).show()
    }

    _hideTarget(target) {
        $(target).hide()
    }

    _getPackagingItems(incidenceDynamicCheckTypeId) {
        let context = this
        let url = '/admin/packaging_items/get_packaging_items'
        $.ajax({
            url: url,
            type: "GET",
            dataType: "script",
            data: {
                'format': 'json',
                'incidence_dynamic_check_type_id': incidenceDynamicCheckTypeId,
            },
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (data) {
                let packagingItems = JSON.parse(data)
                let packagingItemId = context.selectPackagingItemTarget.value
                $(context.selectPackagingItemTarget).empty().trigger('change');
                $.each(packagingItems, function (i, obj) {
                    let option = new Option(obj.name, obj.id);
                    $(context.selectPackagingItemTarget).append(option)
                });
                context._enableTarget(context.selectPackagingItemTarget, true)
                $(context.selectPackagingItemTarget).val(packagingItemId).trigger('change');
            },
            error: function (error) {
                $.app.error(error.responseText)
            }
        })
    }

}